<script>
import DefaultMixin from '../mixin'
import RequerAtencao from "components/console/components/clientes/dashboard/RequerAtencao.vue"
import Stats from "components/console/components/clientes/dashboard/Stats.vue"
import Leitor from "components/fenaju/components/dashboard/Leitor.vue"
import Resumo from "components/fenaju/components/dashboard/Resumo.vue"
import ClientesOs from "components/console/components/clientes/dashboard/OrdemServico.vue";

export default {
  name: 'ConsoleDashboard',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    ClientesOs,
    //Resumo,
    //Leitor,
    Stats,
    RequerAtencao,
  },
  data() {
    return {
      menuActive: 'suporte'
    }
  },
  computed: {
  },
  beforeMount () {
  },
  watch: {
  },
  mounted() {
    document.body.classList.add('console-clientes-dashboard')
  },
  destroyed() {
    document.body.classList.remove('console-clientes-dashboard')
  },
  methods: {
  },
  meta: {
    title: 'Console',
    name: 'Console'
  }
}
</script>

<template>
    <div class="console-dashboard">
      <div class="console-dash-menu-container">
        <div class="console-dash-menu console-dash-menu-level1" :class="{maxWidth: $route.name === 'console.clientes'}">
          <u-btn @click="$router.push({name: 'console.clientes'})" class="opt-btn" :class="{active: $route.name === 'console.clientes'}"><i class="fa-duotone fa-chart-bar" /> Dashboard</u-btn>
          <u-btn @click="$router.push({name: 'console.clientes.lista'})" class="opt-btn" :class="{active: $route.name === 'console.clientes.lista' && !$route.query.modoFinanceiro}"><i class="fal fa-square-list" /> Lista</u-btn>
          <u-btn @click="$router.push({name: 'console.clientes.lista', query: {modoFinanceiro: 1}})" class="opt-btn" :class="{active: $route.name === 'console.clientes.lista' && $route.query.modoFinanceiro}"><i class="fal fa-money-check" /> Lista Modo Financeiro</u-btn>
        </div>
      </div>
      <div v-show="$route.name === 'console.clientes'" class="ev2-body">
        <div class="content-limit">
          <div class="content flex">
            <clientes-os />
            <requer-atencao />
            <stats />
          </div>
<!--          <div class="content flex">
            <leitor />
            <resumo />
          </div>-->
        </div>
      </div>
      <router-view />
    </div>
</template>

<style lang="stylus">
.console-dash-menu.maxWidth {
  max-width 1440px
}
</style>
